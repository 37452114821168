import footerSVG from '../../Assets/FooterSVG.svg';
import LogoFooter from '../../Assets/LogoFooter';
// import useMobile from '../../Hooks/useMobile';

export default function Footer() {
    // const { isMobile } = useMobile()

    return <footer className="App_Footer" style={{
        backgroundImage: `url(${footerSVG})`,
    }}>

        <LogoFooter />

        <div className="App_Footer_ContactBox">
            <h4>Ubicación y Teléfonos</h4>

            <p>
                <i>Educando por un planeta verde</i><br />
                Nuestro colegio está ubicado en Calle Nueva 2801, Coquimbo.
            </p>
            
        </div>

    </footer>
}