import * as React from "react"
import { SVGProps } from "react"

type IconTypes = 'time' |
    'mail' |
    'phone' |
    'instagram' |
    'whatsapp' |
    'facebook'

interface IconProps extends SVGProps<SVGElement> {
    type: IconTypes
    color?: string
    size?: number
}

const Icon = (allProps: IconProps) => {
    const { type, color, size, ref, ...props } = allProps

    const icons = new Map<IconTypes, JSX.Element>([
        ['time', <path fill={color || "#000"} d="M128 42.667c47.051 0 85.333 38.282 85.333 85.333S175.051 213.333 128 213.333c-47.05 0-85.333-38.282-85.333-85.333 0-47.05 38.282-85.333 85.333-85.333Zm0-21.334C69.088 21.333 21.333 69.088 21.333 128S69.088 234.667 128 234.667 234.667 186.912 234.667 128 186.912 21.333 128 21.333Zm40.533 142.934-29.866-39.819V74.667h-21.334v56.885l34.134 45.504 17.066-12.789Z" />],
        ['mail', <path fill={color || "#000"} d="M32 32h192a10.665 10.665 0 0 1 10.667 10.667v170.666A10.667 10.667 0 0 1 224 224H32a10.668 10.668 0 0 1-10.667-10.667V42.667A10.667 10.667 0 0 1 32 32Zm181.333 45.205-84.565 75.734-86.101-75.968v125.696h170.666V77.205ZM48.117 53.333l80.534 71.062 79.37-71.062H48.117Z" />],
        ['phone', <path fill={color || "#000"} fillRule="evenodd" d="M61.152 21.792c12.981-12.907 34.357-10.613 45.227 3.915l13.461 17.962c8.853 11.819 8.064 28.33-2.443 38.774l-2.538 2.538a6.968 6.968 0 0 0-.086 3.264c.672 4.352 4.31 13.568 19.542 28.715 15.232 15.147 24.512 18.773 28.928 19.456a7.138 7.138 0 0 0 3.36-.096l4.352-4.331c9.344-9.28 23.68-11.018 35.242-4.736l20.374 11.094c17.461 9.472 21.866 33.194 7.573 47.413l-15.157 15.061c-4.779 4.747-11.2 8.704-19.03 9.44-19.306 1.803-64.288-.501-111.573-47.509-44.128-43.883-52.598-82.155-53.675-101.013-.533-9.536 3.968-17.6 9.707-23.296l16.736-16.651Zm32.427 13.504C88.17 28.075 78.1 27.499 72.427 33.14L55.68 49.781c-3.52 3.499-5.206 7.36-4.992 11.05.853 14.988 7.68 49.516 48.981 90.582 43.328 43.072 83.339 44.363 98.806 42.912 3.157-.288 6.293-1.93 9.226-4.842l15.147-15.072c6.165-6.123 4.811-17.28-3.915-22.016l-20.373-11.083c-5.632-3.051-12.224-2.048-16.32 2.027l-4.853 4.832-5.654-5.675c5.654 5.675 5.643 5.685 5.632 5.685l-.01.022-.032.032-.075.064-.16.149c-.454.414-.939.792-1.451 1.131a16.478 16.478 0 0 1-3.402 1.717c-2.88 1.077-6.699 1.653-11.414.928-9.248-1.419-21.504-7.723-37.792-23.915-16.277-16.192-22.634-28.373-24.064-37.61-.736-4.715-.15-8.534.939-11.414a16.269 16.269 0 0 1 2.539-4.48l.341-.373.149-.16.064-.064.032-.032.022-.021 3.072-3.051c4.565-4.555 5.205-12.096.906-17.845l-13.45-17.963Z" clipRule="evenodd" />],
        ['instagram', <path fill={color || "#000"} d="M64 0C46.632 0 44.448.08 37.624.384 30.8.704 26.152 1.776 22.08 3.36a31.2 31.2 0 0 0-11.336 7.384A31.2 31.2 0 0 0 3.36 22.08C1.776 26.144.696 30.8.384 37.6.08 44.44 0 46.616 0 64.008 0 81.384.08 83.56.384 90.384c.32 6.816 1.392 11.464 2.976 15.536 1.64 4.208 3.824 7.776 7.384 11.336 3.552 3.56 7.12 5.752 11.328 7.384 4.08 1.584 8.72 2.664 15.536 2.976 6.832.304 9.008.384 26.392.384s19.552-.08 26.384-.384c6.808-.32 11.472-1.392 15.544-2.976a31.207 31.207 0 0 0 11.328-7.384c3.56-3.56 5.744-7.128 7.384-11.336 1.576-4.072 2.656-8.72 2.976-15.536.304-6.824.384-9 .384-26.384s-.08-19.56-.384-26.392c-.32-6.808-1.4-11.464-2.976-15.528a31.203 31.203 0 0 0-7.384-11.336A31.201 31.201 0 0 0 105.92 3.36C101.84 1.776 97.184.696 90.376.384 83.544.08 81.376 0 63.984 0H64Zm-5.736 11.536h5.744c17.088 0 19.112.056 25.856.368 6.24.28 9.632 1.328 11.888 2.2 2.984 1.16 5.12 2.552 7.36 4.792 2.24 2.24 3.624 4.368 4.784 7.36.88 2.248 1.92 5.64 2.2 11.88.312 6.744.376 8.768.376 25.848s-.064 19.112-.376 25.856c-.28 6.24-1.328 9.624-2.2 11.88a20 20 0 0 1-4.792 7.352c-2.24 2.24-4.368 3.624-7.36 4.784-2.24.88-5.632 1.92-11.88 2.208-6.744.304-8.768.376-25.856.376s-19.12-.072-25.864-.376c-6.24-.288-9.624-1.328-11.88-2.208a20.001 20.001 0 0 1-7.36-4.784 19.997 19.997 0 0 1-4.8-7.36c-.872-2.248-1.92-5.64-2.2-11.88-.304-6.744-.368-8.768-.368-25.864s.064-19.104.368-25.848c.288-6.24 1.328-9.632 2.208-11.888 1.16-2.984 2.552-5.12 4.792-7.36 2.24-2.24 4.368-3.624 7.36-4.784 2.256-.88 5.64-1.92 11.88-2.208 5.904-.272 8.192-.352 20.12-.36v.016ZM98.168 22.16a7.679 7.679 0 1 0 0 15.358 7.679 7.679 0 0 0 0-15.358Zm-34.16 8.976a32.874 32.874 0 0 0-23.575 56.293 32.873 32.873 0 0 0 55.934-23.425 32.872 32.872 0 0 0-32.359-32.868Zm0 11.528a21.336 21.336 0 1 1 0 42.673 21.336 21.336 0 0 1 0-42.673Z" />],
        ['whatsapp', <path fill={color || "#000"} d="M109.128 18.6A62.955 62.955 0 0 0 64.256 0C29.304 0 .856 28.448.856 63.424a63.37 63.37 0 0 0 8.472 31.704L.328 128l33.624-8.824a63.428 63.428 0 0 0 30.296 7.728h.024c34.952 0 63.424-28.448 63.424-63.424a63.108 63.108 0 0 0-18.576-44.872l.008-.008Zm-44.856 97.6a52.663 52.663 0 0 1-26.848-7.352l-1.928-1.152-19.952 5.224 5.328-19.448-1.248-2a52.295 52.295 0 0 1-8.072-28.048 52.791 52.791 0 0 1 52.752-52.728c14.072 0 27.328 5.504 37.272 15.448A52.429 52.429 0 0 1 117 63.44c-.024 29.096-23.672 52.76-52.728 52.76Zm28.904-39.496c-1.576-.8-9.376-4.624-10.824-5.152-1.448-.528-2.504-.8-3.576.8-1.048 1.576-4.096 5.152-5.024 6.224-.928 1.048-1.848 1.2-3.424.4-1.576-.8-6.704-2.472-12.752-7.872-4.704-4.2-7.896-9.4-8.824-10.976-.928-1.576-.104-2.448.704-3.224.728-.704 1.576-1.848 2.376-2.776.8-.928 1.048-1.576 1.576-2.648.528-1.048.272-1.976-.128-2.776-.4-.8-3.576-8.6-4.872-11.776-1.272-3.104-2.6-2.672-3.576-2.728-.928-.048-1.976-.048-3.024-.048s-2.776.4-4.224 1.976c-1.448 1.576-5.552 5.424-5.552 13.224s5.672 15.328 6.472 16.4c.8 1.048 11.176 17.072 27.072 23.928 3.776 1.624 6.728 2.6 9.024 3.352 3.8 1.2 7.248 1.024 9.976.624 3.048-.448 9.376-3.824 10.704-7.528 1.328-3.704 1.328-6.872.928-7.528-.376-.704-1.424-1.096-3.024-1.904l-.008.008Z" />],
        ['facebook', <path fill={color || "#000"} d="M111.467 10.667H16.533a5.866 5.866 0 0 0-5.866 5.866v94.934a5.865 5.865 0 0 0 5.866 5.866h51.094V76H53.76V60h13.867V48A19.415 19.415 0 0 1 88.32 26.667c4.151-.026 8.3.188 12.427.64v14.4h-8.48c-6.72 0-8 3.2-8 7.84V59.84h16l-2.08 16h-13.92v41.493h27.2a5.865 5.865 0 0 0 5.866-5.866V16.533a5.863 5.863 0 0 0-5.866-5.866Z" />],
    ])

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || 256}
        height={size || 256}
        fill="none"
        {...props}
        viewBox="0 0 128 128"
        className="Icon"
    >
        {icons.get(type)}
    </svg>
}
export default Icon