import { Link, LinkProps } from 'react-router-dom'

import logo from '../../Assets/Logo.png';
import useInfo from '../../Hooks/useInfo';
import { memo, useState } from 'react';
import React from 'react';
import Icon from '../../Assets/Icon';

import * as Constants from '../../constants'
import useMobile from '../../Hooks/useMobile';

const TopBar = () => {
    return <header className="App_TopBar">
        <div className="App_TopBar__Left">
            <span><a rel="noreferrer" target='_blank' href={`tel:+${Constants.TOPBAR_PHONE.replaceAll(' ', '').replaceAll('+', '')}`}><Icon type={'phone'} color={'#FFFFFF'} size={12} />  {Constants.TOPBAR_PHONE}</a></span>
            <span><a rel="noreferrer" target='_blank' href={`mailto:${Constants.TOPBAR_MAIL}`}><Icon type={'mail'} color={'#FFFFFF'} size={12} />{Constants.TOPBAR_MAIL}</a></span>
        </div>
        <div className="App_TopBar__Right">
            <span><Icon type={'time'} color={'#FFFFFF'} size={12} /> Lun a Vie entre las 08:00 y 16:00</span>
            {/* Portal Certificados */}
        </div>
    </header>
}

interface ItemProps extends LinkProps {
    //
    children: JSX.Element | string
    items?: JSX.Element[]
}

const Item = (props: ItemProps) => {
    const { children, items, ..._props } = props
    return <li>
        <Link {..._props}>{children}</Link>
        {items && items?.length > 0 && <ul>
            {items.map(item => item)}
        </ul>}
    </li>
}

const Header = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const { menues } = useInfo() // , minuta, antiguasMinutas
    
    const { isMobile } = useMobile()

    const renderMenu = () => {

        return <menu className={`App_Header_Menu ${showMenu ? 'App_Header_Menu_Show' : ''}`}>
            <ul className="App_Header_Menu_Ul">
                <Item to="/" className="App_Header_Menu_Item">Inicio</Item>
                <Item to="#" className="App_Header_Menu_Item" items={menues.map(menu => {
                    return <Item key={`MenuItem${menu.id}`} to={menu.attributes.link} className="App_Header_Menu_Item">{menu.attributes.title}</Item>
                })}>Colegio</Item>
                <Item to="/noticias" className="App_Header_Menu_Item">Noticias</Item>
                <Item to="/galerias" className="App_Header_Menu_Item">Galerías</Item>
                <Item to="/admision" className="App_Header_Menu_Item">Admisión</Item>
                <Item to="/comunicados" className="App_Header_Menu_Item">Comunicados</Item>
                {/* <Item to="/minutas" className="App_Header_Menu_Item">Minutas</Item> */}

                <div style={{ display: 'flex', gap: 24, marginLeft: 8 }}>
                <Item referrerPolicy='no-referrer' target='_blank' to="https://www.instagram.com/eusebiolillo.colegio/" className="App_Header_Menu_Item RRSSButton">
                    <Icon size={20} type='instagram' />
                </Item>

                <Item referrerPolicy='no-referrer' target='_blank' to="https://www.facebook.com/Colegioeusebiolillo?locale=es_LA" className="App_Header_Menu_Item RRSSButton">
                    <Icon size={20} type='facebook' />
                </Item>

                <Item referrerPolicy='no-referrer' target='_blank' to="https://wa.me/56922017151" className="App_Header_Menu_Item RRSSButton">
                    <Icon size={20} type='whatsapp' />
                </Item>
                </div>

                {/* 
                    {minuta && <Item target='_blank' referrerPolicy='no-referrer' to={minuta.attributes.pdf.data.attributes.url} className="App_Header_Menu_Item App_Header_Menu_Item_Starred">Minuta JUNAEB</Item>}
                    {antiguasMinutas && <Item referrerPolicy='no-referrer' to={'#'} className="App_Header_Menu_Item App_Header_Menu_Item_Starred_Outlined" items={antiguasMinutas.map((antiguaMinuta: IMinuta) => {
                        return <Item target='_blank' key={`MenuItem${antiguaMinuta.id}`} to={antiguaMinuta.attributes.pdf.data.attributes.url} className="App_Header_Menu_Item">{antiguaMinuta.attributes.title}</Item>
                    })}>+</Item>}
                </div> */}
            </ul>
        </menu>
    }

    return <React.Fragment>
        <TopBar />
        <header className="App_Header">
        
        <Link to="/">
            <img src={logo} alt="logo" className="App_Header_Logo" />
        </Link>
        {/* Menú mobile */}
        {isMobile && <button onClick={() => {
            setShowMenu(_s => !_s)
        }}>Menú</button>}
        {!isMobile && renderMenu()}
    </header>
    {isMobile && renderMenu()}
    </React.Fragment>
}

export default memo(Header)